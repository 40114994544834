import Place from "../models/Place";

let service: google.maps.Geocoder | null = null;

const fetchPlace = async (placeId: string): Promise<Place | null> => {
  if (!service && (window as any).google) {
    service = new (window as any).google.maps.Geocoder();
  }

  if (!service) {
    return null;
  }

  const response = await service.geocode({ placeId });
  const results: google.maps.GeocoderResult[] = response.results;

  if (results.length > 0) {
    const result = results[0];
    const location = result.geometry.location;
    const lat = location.lat();
    const lng = location.lng();

    let country: string | null = null;
    let postalCode: string | null = null;
    let city: string | null = null;
    let street: string | null = null;
    let streetNumber: string | null

    for (let component of result.address_components) {
      if (component.types.includes('country')) {
        country = component.short_name;
      }
      if (component.types.includes('postal_code')) {
        postalCode = component.long_name;
      }
      if (component.types.includes('locality')) {
        city = component.long_name;
      }
      if (component.types.includes('route')) {
        street = component.long_name;
      }
      if (component.types.includes('street_number')) {
        streetNumber = component.long_name;
      }
    }

    return { id: placeId, lat, lng, country, postalCode, city, street, streetNumber };
  }

  return null;
}

export default fetchPlace;