import React from 'react';
import PropTypes from 'prop-types';
import InputGroup from '../InputGroup';
import Input from '../Input';
import PhoneInput from '../PhoneInput';
import TextArea from '../TextArea';
import CountrySelect from '../CountrySelect';
import TermsCheckbox from './TermsCheckbox';
import NumberFormat from 'react-number-format';
import InputSelect from 'components/BookingForm/InputSelect';
import LargeInputGroup from '../LargeInputGroup';
import { useTranslation } from 'react-i18next';

function DeliveryAddressStep(props){
  const { countries, deliveryAddressData, onChangeDeliveryAddressData, deliveryAddressDataErrors,
          pickupAddressData, onChangePickupAddressData, travelType } = props;
  const { t } = useTranslation();
  return (
    <div>
      <div className='row pdt-24 font-black'>
        <div className='col-xs-12'>
          <p className='text-center'>{t('deliveryAddressDescription')}</p>
          <table className='wd-100-percent'>
            <InputGroup label={t('accommodationNameStep4')} error={deliveryAddressDataErrors.accommodatie} description={t('accommodatieDescription')}>
              <Input
                value={deliveryAddressData['accommodatie']}
                onChange={onChangeDeliveryAddressData}
                concept='accommodatie'
              />
            </InputGroup>
            <InputGroup label={t('streetName')} error={deliveryAddressDataErrors.street}>
              <Input
                value={deliveryAddressData['street']}
                onChange={onChangeDeliveryAddressData}
                concept='street'
              />
            </InputGroup>
            <InputGroup label={t('houseNumber')} error={deliveryAddressDataErrors.address_number}>
              <Input
                value={deliveryAddressData['address_number']}
                onChange={onChangeDeliveryAddressData}
                concept='address_number'
              />
            </InputGroup>
            <InputGroup label={t('zipCode')} error={deliveryAddressDataErrors.postal_code}>
              <Input
                value={deliveryAddressData['postal_code']}
                onChange={onChangeDeliveryAddressData}
                concept='postal_code'
              />
            </InputGroup>
            <InputGroup label={t('city')} error={deliveryAddressDataErrors.city}>
              <Input
                value={deliveryAddressData['city']}
                onChange={onChangeDeliveryAddressData}
                concept='city'
              />
            </InputGroup>
            <InputGroup label={t('country')} error={deliveryAddressDataErrors.country}>
              <CountrySelect
                countries={countries}
                value={deliveryAddressData['country']}
                onChange={onChangeDeliveryAddressData}
              />
            </InputGroup>
            <InputGroup label={t('phoneNumberStep2')} error={deliveryAddressDataErrors.phone}>
              <PhoneInput
                value={deliveryAddressData['phone']}
                onChange={onChangeDeliveryAddressData}
                concept='phone'
              />
            </InputGroup>
            <InputGroup label={t('emailStep2')} error={deliveryAddressDataErrors.email}>
              <Input
                value={deliveryAddressData['email']}
                onChange={onChangeDeliveryAddressData}
                concept='email'
              />
            </InputGroup>
            <InputGroup label={t('otherInformationStep4')} error={deliveryAddressDataErrors.other_information}>
              <TextArea
                value={deliveryAddressData['other_information']}
                onChange={onChangeDeliveryAddressData}
                concept='other_information'
              />
            </InputGroup>
            <InputGroup label={t('termsAndConditionsStep4')}  error={deliveryAddressDataErrors.terms_and_conditions}>
              <TermsCheckbox
                value={deliveryAddressData['terms_and_conditions']}
                onChange={onChangeDeliveryAddressData}
              />
            </InputGroup>
            { travelType === 'return' &&
              <LargeInputGroup
                label={t('collectLuggageOption')}
              >
                <InputSelect
                  value={deliveryAddressData.is_the_same_pickup_address}
                  onChange={onChangeDeliveryAddressData}
                  concept='is_the_same_pickup_address'
                />
              </LargeInputGroup>
            }
            { !deliveryAddressData.is_the_same_pickup_address &&
              <InputGroup label={t('accommodationNameStep4')} error={deliveryAddressDataErrors.pickup_accommodatie}>
                <Input
                  value={pickupAddressData['accommodatie']}
                  onChange={onChangePickupAddressData}
                  concept='accommodatie'
                />
              </InputGroup>
            }
            { !deliveryAddressData.is_the_same_pickup_address &&
              <InputGroup label={t('streetName')} error={deliveryAddressDataErrors.pickup_street}>
                <Input
                  value={pickupAddressData['street']}
                  onChange={onChangePickupAddressData}
                  concept='street'
                />
              </InputGroup>
            }
            { !deliveryAddressData.is_the_same_pickup_address &&
              <InputGroup label={t('houseNumber')} error={deliveryAddressDataErrors.pickup_address_number}>
                <Input
                  value={pickupAddressData['address_number']}
                  onChange={onChangePickupAddressData}
                  concept='address_number'
                />
              </InputGroup>
            }
            { !deliveryAddressData.is_the_same_pickup_address &&
              <InputGroup label={t('zipCode')} error={deliveryAddressDataErrors.pickup_postal_code}>
                <Input
                  value={pickupAddressData['postal_code']}
                  onChange={onChangePickupAddressData}
                  concept='postal_code'
                />
              </InputGroup>
            }
            { !deliveryAddressData.is_the_same_pickup_address &&
              <InputGroup label={t('city')} error={deliveryAddressDataErrors.pickup_city}>
                <Input
                  value={pickupAddressData['city']}
                  onChange={onChangePickupAddressData}
                  concept='city'
                />
              </InputGroup>
            }
            { !deliveryAddressData.is_the_same_pickup_address &&
              <InputGroup label={t('country')} error={deliveryAddressDataErrors.pickup_country}>
                <CountrySelect
                  countries={countries}
                  value={pickupAddressData['country']}
                  onChange={onChangePickupAddressData}
                />
              </InputGroup>
            }
            { !deliveryAddressData.is_the_same_pickup_address &&
              <InputGroup label={t('phoneNumberStep2')} error={deliveryAddressDataErrors.pickup_phone}>
                <PhoneInput
                  value={pickupAddressData['phone']}
                  onChange={onChangePickupAddressData}
                  concept='phone'
                />
              </InputGroup>
            }
          </table>
        </div>
      </div>
    </div>
  )
}

export default DeliveryAddressStep;
