enum TripType {
  ONE_WAY = "one_way",
  RETURN = "return"
}

namespace TripType {
  const map = new Map<string, TripType>(
    Object.values(TripType).map((t: TripType) => ([t.toString(), t]))
  );
  
  export const valueOf = (str: string): TripType | undefined => map.get(str);
}

export default TripType;