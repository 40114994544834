import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ObjectValidator from 'helpers/ObjectValidator';
import Money from 'helpers/Money';
import axios from 'axios';
import '../i18n';
import BookingForm from './BookingForm';
import EmailValidator from 'email-validator';

import {
  contactDataSchema,
  shippingAddressDataSchema,
  deliveryAddressDataSchema,
  OneWayShippingAddressDataSchema,
  returnAddressDataSchema,
  pickupAddressDataSchema,
} from 'schemas';
import validate from 'validate';

export default class BookingContainer extends React.Component {
  constructor(props) {
    super(props);
    const { countries, luggages, extraLuggages, currentUser } = props;

    const typesOfLuggage = luggages;

    const steps = [1, 2, 3, 4, 5];

    const selectedLuggages = {};

    typesOfLuggage.forEach((typeOfLuggage) => {
      selectedLuggages[typeOfLuggage.id] = 0;
    })

    const shippingAddressData = {
      'departure_date': undefined,
      'return_date': undefined,
      'name': '',
      'street': '',
      'address_number': '',
      'postal_code': '',
      'city': '',
      'country': countries,
      'is_the_same_return_address': true,
    };

    const returnAddressData = {
      'street': '',
      'address_number': '',
      'postal_code': '',
      'city': '',
      'country': countries,
    };

    const contactData = {
      'email': '',
      'title': 'Mr.',
      'first_name': '',
      'last_name': '',
      'phone': '',
    };

    const deliveryAddressData = {
      'accommodatie': '',
      'street': '',
      'address_number': '',
      'postal_code': '',
      'city': '',
      'country': countries,
      'phone': '',
      'email': '',
      'other_information': '',
      'terms_and_conditions': false,
      'is_the_same_pickup_address': true,
    };

    const pickupAddressData = {
      'accommodatie': '',
      'street': '',
      'address_number': '',
      'postal_code': '',
      'city': '',
      'country': countries,
      'phone': '',
    }

    this.paymentTypes = [
      { name: 'iDeal' },
      { name: 'pinnen', isManual: true },
    ]

    this.state = {
      typesOfLuggage,
      extraLuggages,
      steps,
      selectedLuggages,
      shippingAddressData,
      returnAddressData,
      contactData,
      deliveryAddressData,
      pickupAddressData,
      extraLuggage: '',
      currentStep: steps[0],
      selectedPaymentType: this.paymentTypes[0],
      transactionId: '',
      contactDataErrors: {},
      shippingAddressDataErrors: {},
      deliveryAddressDataErrors: {},
      travelType: 'one_way',
    }
  }

  handleChangeTransactionId = (event) => {
    this.setState({
      transactionId: event.target.value,
    })
  }

  handleSelectPaymentType = (event) => {
    const paymentTypeName = event.target.value
    this.setState({
      selectedPaymentType: this.paymentTypes.find(paymentType => paymentType.name === paymentTypeName),
    });
  }

  handleNextStep = () => {
    const response = this.validateCurrentStep();

    if (response.isValid) {
      this.setState({
        currentStep: this.state.currentStep + 1,
      })
    } else {
      this.setState({
        [response.objectName]: response.errorObject,
      })
    }
  }

  handleBackStep = () => {
    this.setState({
      currentStep: this.state.currentStep - 1,
    })
  }

  handleLuggageQuantity = (typeOfLuggage, value) => {
    const { selectedLuggages } = this.state
    selectedLuggages[typeOfLuggage.id] = value;

    if (!this.isAbleToChangeExtraLuggage()) {
      this.setState({
        extraLuggage: "",
      });
    }

    this.setState({
      selectedLuggages,
    })
  }

  handleShippingAddress = (concept, value) => {
    const { shippingAddressData } = this.state;
    shippingAddressData[concept] = value;
    this.setState({
      shippingAddressData,
    });
  }

  handleReturnAddress = (concept, value) => {
    const { returnAddressData } = this.state;
    returnAddressData[concept] = value;
    this.setState({
      returnAddressData,
    });
  }

  handlePickupAddress = (concept, value) => {
    const { pickupAddressData } = this.state;
    pickupAddressData[concept] = value;
    this.setState({
      pickupAddressData,
    });
  }

  handleContact = (concept, value) => {
    const { contactData } = this.state;
    contactData[concept] = value;
    this.setState({
      contactData,
    });
  }

  handleDeliveryAddressData = (concept, value) => {
    const { deliveryAddressData } = this.state;
    deliveryAddressData[concept] = value;
    this.setState({
      deliveryAddressData,
    });
  }

  handleExtraLuggage = ev => {
    this.setState({extraLuggage: ev.target.value})
  }

  validateCurrentStep = () => {
    const { currentStep } = this.state;
    switch(currentStep) {
      case 1:
        return this.validateLuggageStep()
        break;
      case 2:
        return this.validateContactStep()
        break;
      case 3:
        return this.validateShippingAddress()
        break;
      case 4:
        return this.validateDeliveryAddress()
        break;
      default:
        return true;
    }
  }

  validateLuggageStep = () => {
    const { selectedLuggages } = this.state;
    const sumOfLuggages =
      Object.keys(selectedLuggages).reduce((a, b) => a + selectedLuggages[b], 0)
    return { isValid: sumOfLuggages > 0 };
  }

  validateContactStep = () => {
    const { contactData } = this.state;
    const result = validate(contactData, contactDataSchema, 'contactDataErrors');

    return result;
  }

  validateShippingAddress = () => {
    const { shippingAddressData, returnAddressData, travelType } = this.state;

    const schema = travelType == 'one_way' ? OneWayShippingAddressDataSchema : shippingAddressDataSchema;

    const shippingAddressResult =
      validate(shippingAddressData, schema, 'shippingAddressDataErrors');
    const returnAddressResult =
      validate(returnAddressData, returnAddressDataSchema, 'returnAddressDataErrors');

    if (!shippingAddressData.is_the_same_return_address) {
      Object.keys(returnAddressResult.errorObject).forEach((key) => {
        shippingAddressResult.errorObject[`return_${key}`] = returnAddressResult.errorObject[key];
      })
      shippingAddressResult.isValid = shippingAddressResult.isValid && returnAddressResult.isValid;
    }

    return shippingAddressResult;
  }

  validateDeliveryAddress = () => {
    const { deliveryAddressData, pickupAddressData } = this.state;

    const deliveryAddressResult =
      validate(deliveryAddressData, deliveryAddressDataSchema, 'deliveryAddressDataErrors');

    const pickupAddressResult =
      validate(pickupAddressData, pickupAddressDataSchema, 'pickupAddressDataErrors');

    if (!deliveryAddressData.is_the_same_pickup_address) {
      Object.keys(pickupAddressResult.errorObject).forEach((key) => {
        deliveryAddressResult.errorObject[`pickup_${key}`] = pickupAddressResult.errorObject[key];
      })
      deliveryAddressResult.isValid = deliveryAddressResult.isValid && pickupAddressResult.isValid;
    }

    return deliveryAddressResult;
  }

  areValidAllSteps = () => {
    const isValidContactStep = this.validateContactStep().isValid;
    const isValidShippingAddress = this.validateShippingAddress().isValid;
    const isValidDeliveryAddress = this.validateDeliveryAddress().isValid;
    const isValidLuggageStep = this.validateLuggageStep().isValid;

    return isValidLuggageStep &&
      isValidContactStep &&
      isValidShippingAddress &&
      isValidDeliveryAddress;
  }

  calculatePrices = () => {
    const { selectedLuggages, typesOfLuggage, extraLuggage, travelType } = this.state;
    const { extraLuggages } = this.props;
    const priceAttribute = travelType == 'return' ? 'round_trip_price_cents' : 'one_way_price_cents';
    const sumOfLuggagesPrices =
      Object
        .keys(selectedLuggages)
        .reduce((memo, luggageId) => {
          let quantitySelected = selectedLuggages[luggageId];
          let selectedLuggagePrice =
            typesOfLuggage.filter(luggage => luggage.id == luggageId)[0][priceAttribute];
          return memo + (selectedLuggagePrice * quantitySelected);
        }, 0 );
    const extraLuggagePrice =
      extraLuggage == '' ? 0 : extraLuggages.filter(el => el.id == extraLuggage)[0].price_cents;
    const total = sumOfLuggagesPrices + extraLuggagePrice;
    const VAT = total * 0.21;
    const priceWithoutVAT = total - VAT;
    return {
      total: Money.fromCents(total),
      VAT: Money.fromCents(VAT),
      priceWithoutVAT: Money.fromCents(priceWithoutVAT),
    }
  }

  sendRequest = () => {
    const { authenticityToken } = this.props;
    const { contactData, deliveryAddressData, paymentType, transactionId, selectedPaymentType,
            extraLuggage, travelType, returnAddressData, pickupAddressData } = this.state;
    const shippingAddressData = Object.assign({}, this.state.shippingAddressData);
    shippingAddressData.departure_date = moment(shippingAddressData.departure_date).format('DD/MM/YYYY');
    shippingAddressData.return_date =
      (!shippingAddressData.return_date) ? '' : moment(shippingAddressData.return_date).format('DD/MM/YYYY');

    const returnAddress =
      shippingAddressData.is_the_same_return_address ? shippingAddressData : returnAddressData;
    const pickupAddress =
      deliveryAddressData.is_the_same_pickup_address ? deliveryAddressData : pickupAddressData;

    const data = {
      authenticity_token: authenticityToken,
      booking: {
        contact_information: contactData,
        shipping_address: shippingAddressData,
        delivery_address: deliveryAddressData,
        luggages: this.parsedLuggages(),
        transactionId: transactionId,
        paymentType: selectedPaymentType.name,
        extra_luggage_id: extraLuggage,
        travel_type: travelType,
        return_address: returnAddress,
        pickup_address: pickupAddress,
      },
    }
    axios.post('/bookings', data)
      .then((response) => {
        window.location = response.data.redirect_url;
      });
  }

  parsedLuggages = () => {
    const { selectedLuggages } = this.state;
    const parsedLuggages = [];
    Object.keys(selectedLuggages).forEach((key) => {
      parsedLuggages.push({luggage_id: key, quantity: selectedLuggages[key]})
    });
    return parsedLuggages;
  }

  onChangeStep = (step) => {
    this.setState({
      currentStep: step,
    });
  }

  stepValidations = () => {
    const isValidLuggageStep = this.validateLuggageStep().isValid;
    const isValidContactStep = this.validateContactStep().isValid;
    const isValidShippingAddress = this.validateShippingAddress().isValid;

    return [
      { stepKey: 1, isValid: true },
      { stepKey: 2, isValid: isValidLuggageStep },
      { stepKey: 3, isValid: isValidLuggageStep && isValidContactStep },
      { stepKey: 4, isValid: isValidLuggageStep && isValidContactStep && isValidShippingAddress },
      { stepKey: 5, isValid: this.areValidAllSteps() },
    ]
  }

  handleChangeTravelType = (ev) => {
    this.setState({
      travelType: ev.target.value,
    });
  }

  isAbleToChangeExtraLuggage = () => {
    const { selectedLuggages, typesOfLuggage } = this.state;

    return Object.keys(selectedLuggages)
      .filter(id => selectedLuggages[id] > 0)
      .map(id => typesOfLuggage.find(t => t.id === parseInt(id)))
      .some(s => s.name.includes('Koffer') || s.name.includes('koffer'));
  }

  render() {
    const { typesOfLuggage, extraLuggages, currentStep,
      steps, selectedLuggages, shippingAddressData, contactData,
      deliveryAddressData, pricing, extraLuggage, selectedPaymentType,
      transactionId, contactDataErrors, shippingAddressDataErrors,
      deliveryAddressDataErrors, travelType, returnAddressData, pickupAddressData } = this.state;
    const { countries, restrictedDates } = this.props;
    return (
      <BookingForm
        typesOfLuggage={typesOfLuggage}
        extraLuggages={extraLuggages}
        currentStep={currentStep}
        steps={steps}
        onClickNextStep={this.handleNextStep}
        onClickBackStep={this.handleBackStep}
        onClickPay={this.sendRequest}
        onChangeLuggageQuantity={this.handleLuggageQuantity}
        onChangeShippingAddress={this.handleShippingAddress}
        onChangeDeliveryAddressData={this.handleDeliveryAddressData}
        onChangeContact={this.handleContact}
        onChangeExtraLuggage={this.handleExtraLuggage}
        selectedLuggages={selectedLuggages}
        countries={countries}
        shippingAddressData={shippingAddressData}
        deliveryAddressData={deliveryAddressData}
        contactData={contactData}
        pricing={this.calculatePrices()}
        extraLuggage={extraLuggage}
        onChangeStep={this.onChangeStep}
        validSteps={this.stepValidations()}
        currentUser={this.props.currentUser}
        paymentTypes={this.paymentTypes}
        selectedPaymentType={selectedPaymentType}
        onSelectPaymentType={this.handleSelectPaymentType}
        transactionId={transactionId}
        onChangeTransactionId={this.handleChangeTransactionId}
        contactDataErrors={contactDataErrors}
        shippingAddressDataErrors={shippingAddressDataErrors}
        deliveryAddressDataErrors={deliveryAddressDataErrors}
        isValidLuggageStep={this.validateLuggageStep().isValid}
        onChangeTravelType={this.handleChangeTravelType}
        travelType={travelType}
        returnAddressData={returnAddressData}
        onChangeReturnAddress={this.handleReturnAddress}
        pickupAddressData={pickupAddressData}
        onChangePickupAddressData={this.handlePickupAddress}
        restrictedDates={restrictedDates}
        isAbleToChangeExtraLuggage={this.isAbleToChangeExtraLuggage()}
      />
    )
  }
}

BookingContainer.defaultProps = {
  currentUser: {},
}

BookingContainer.propTypes = {
  currentUser: PropTypes.object,
}
