import React from "react";

import FormSelect from "../shared/FormSelect";

import { Type } from "../models/Luggage";

interface Props {
  locale: string
  empty: string
  types: Array<Type>
  value: number | string
  isValid?: boolean
  helperText?: string
  onChange: (value: number | string) => void
}

const LugaggeTypeSelect: React.FC<Props> = ({ locale, empty, types, value, isValid, helperText, onChange }) => {
  const options = types.map(type => {
    return {
      value: type.id,
      label: type.translations.find(t => t.locale === locale)?.name ?? type.name
    }
  });
  return (
    <FormSelect
      empty={empty}
      value={value}
      isValid={isValid}
      helperText={helperText}
      options={options}
      onChange={onChange} />
  )
}

export default LugaggeTypeSelect;