import Joi from 'joi-browser';

const requiredMessage = (attribute) => () => `${attribute} must be present`
const phoneMessage = (attribute) => () => `${attribute} must be present and must have 10 digits`
const postalMessage = (attribute) => () => `${attribute} must be present and must have 4 digits`
const emailMessage = (attribute) => () => `${attribute} must be present and must be formatted as email`

export const contactDataSchema = Joi.object().keys({
  title: Joi.string().empty(),
  first_name: Joi.string().empty().error(requiredMessage('First name')),
  email: Joi.string().email({ minDomainAtoms: 2 }).error(emailMessage('Email')),
  last_name: Joi.string().empty().error(requiredMessage('Last Name')),
  phone: Joi.string().alphanum().min(10).empty().error(phoneMessage('Phone')),
})

export const shippingAddressDataSchema = Joi.object().keys({
  departure_date: Joi.required().error(requiredMessage('Departure date')),
  return_date: Joi.required().error(requiredMessage('Return date')),
  name: Joi.string().error(requiredMessage('Name')),
  street: Joi.string().error(requiredMessage('Street')),
  address_number: Joi.string().error(requiredMessage('Address number')),
  postal_code: Joi.string().min(4).error(postalMessage('Postal code')),
  city: Joi.string().error(requiredMessage('City')),
  country: Joi.string().error(requiredMessage('Country')),
  is_the_same_return_address: Joi.boolean(),
})

export const returnAddressDataSchema = Joi.object().keys({
  street: Joi.string().error(requiredMessage('Street')),
  address_number: Joi.string().error(requiredMessage('Address number')),
  postal_code: Joi.string().min(4).error(postalMessage('Postal code')),
  city: Joi.string().error(requiredMessage('City')),
  country: Joi.string().error(requiredMessage('Country')),
})

export const OneWayShippingAddressDataSchema = Joi.object().keys({
  departure_date: Joi.required().error(requiredMessage('Departure date')),
  return_date: Joi.optional(),
  name: Joi.string().error(requiredMessage('Name')),
  street: Joi.string().error(requiredMessage('Street')),
  address_number: Joi.string().error(requiredMessage('Address number')),
  postal_code: Joi.string().min(4).error(postalMessage('Postal code')),
  city: Joi.string().error(requiredMessage('City')),
  country: Joi.string().error(requiredMessage('Country')),
  is_the_same_return_address: Joi.boolean(),
})

export const deliveryAddressDataSchema = Joi.object().keys({
  accommodatie: Joi.string().allow('').optional(),
  street: Joi.string().error(requiredMessage('Street')),
  address_number: Joi.string().error(requiredMessage('Address number')),
  postal_code: Joi.string().min(4).error(postalMessage('Postal code')),
  city: Joi.string().error(requiredMessage('City')),
  country: Joi.string().error(requiredMessage('Country')),
  phone: Joi.string().allow('').optional(),
  email: Joi.string().allow('').optional(),
  other_information: Joi.string().allow('').optional(),
  terms_and_conditions: Joi.boolean().valid(true).error(() => 'Must accept the terms and conditions'),
  is_the_same_pickup_address: Joi.boolean(),
})

export const pickupAddressDataSchema = Joi.object().keys({
  accommodatie: Joi.string().allow('').optional(),
  street: Joi.string().error(requiredMessage('Street')),
  address_number: Joi.string().error(requiredMessage('Address number')),
  postal_code: Joi.string().min(4).error(postalMessage('Postal code')),
  city: Joi.string().error(requiredMessage('City')),
  country: Joi.string().error(requiredMessage('Country')),
  phone: Joi.string().allow('').optional(),
})
