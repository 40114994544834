import React from 'react'

export default function Input(props) {
  const { onChange, concept, value } = props;

  const handleChange = (ev) => {
    onChange(concept, ev.target.value);
  }

  return (
    <input
      className='wd-100-percent hg-40 gray-border form-control'
      onChange={handleChange}
      value={value}
    />
  )
}
