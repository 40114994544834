import React from 'react';

export default function LargeInputGroup(props) {
  const { children, label, error } = props;
  return(
    <tr className={`form-group ${error ? 'has-error' : ''}`}>
      <td className="wd-200 font-size-2 pdb-10" colSpan="2">
        {label}
        {children}
      </td>
    </tr>
  )
}
