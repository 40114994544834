import React from 'react';
import PropTypes from 'prop-types';
import TitleSelect from './TitleSelect';
import InputGroup from '../InputGroup';
import Input from '../Input';
import PhoneInput from '../PhoneInput';
import { useTranslation } from 'react-i18next';

function ContactStep(props){
  const { typesOfLuggage, extraLuggages, onClickNextStep, onChangeLuggageQuantity,
    selectedLuggages, onChangeContact, contactData, contactDataErrors } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div className='row pdt-24 font-black'>
        <div className='col-xs-12'>
          <table className='wd-100-percent'>
            <tbody>
              <InputGroup label={t('titleStep2')} error={contactDataErrors.title}>
                <TitleSelect
                  concept='title'
                  value={contactData['title']}
                  onChange={onChangeContact}
                />
              </InputGroup>
              <InputGroup label={t('firstNameStep2')} error={contactDataErrors.first_name}>
                <Input
                  concept='first_name'
                  value={contactData['first_name']}
                  onChange={onChangeContact}
                />
              </InputGroup>
              <InputGroup label={t('lastNameStep2')} error={contactDataErrors.last_name}>
                <Input
                  concept='last_name'
                  value={contactData['last_name']}
                  onChange={onChangeContact}
                />
              </InputGroup>
              <InputGroup label={t('phoneNumberStep2')} error={contactDataErrors.phone}>
                <PhoneInput
                  concept='phone'
                  value={contactData['phone']}
                  onChange={onChangeContact}
                />
              </InputGroup>
              <InputGroup label={t('emailStep2')} error={contactDataErrors.email}>
                <Input
                  concept='email'
                  value={contactData['email']}
                  onChange={onChangeContact}
                />
              </InputGroup>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

ContactStep.propTypes = {
  typesOfLuggages: PropTypes.object,
  extraLuggages: PropTypes.array,
  onClickNextStep: PropTypes.func,
}

export default ContactStep;
