import React from 'react';
import PropTypes from 'prop-types';

export default function CheckBox(props) {
  const { value, onClick, children, concept } = props
  const handleClick = () => {
    onClick(concept, !value)
  }
  return (
    <div className="checkbox">
      <label>
        <input type="checkbox" defaultChecked={value} onClick={handleClick} />
        {children}
      </label>
    </div>
  )
}

CheckBox.propTypes = {
  handleClick: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}
