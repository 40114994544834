import parse from "autosuggest-highlight/parse";

import Address, { HighlightedText } from "../models/Address";
import Country from "../models/Country";
import Place from "../models/Place";

import fetchPlace from "./fetchPlace";

let service: google.maps.places.AutocompleteService | null = null

const fetchAddresses = (input: string, language: string, countries: Array<Country>, callback: (addresses: Array<Address>) => void) => {
  if (!service && (window as any).google) {
    service = new (window as any).google.maps.places.AutocompleteService();
  }

  if (!service) {
    return [];
  }

  const request: google.maps.places.AutocompletionRequest = {
    input,
    language,
    //types: ['address'],
    /* TODO */
    /*componentRestrictions: {
      country: countries.map(country => country.code)
    }*/
  }

  service.getPlacePredictions(request, async (results: google.maps.places.AutocompletePrediction[] | null) => {
    
    const predictions = results || [];

    const addresses: Promise<Address>[] = predictions.map(async prediction => {
      const formatting: google.maps.places.StructuredFormatting = prediction.structured_formatting;
      const matches: google.maps.places.PredictionSubstring[] = formatting.main_text_matched_substrings || [];

      const primaryText: HighlightedText[] = parse(
        formatting.main_text,
        matches.map(match => [match.offset, match.offset + match.length]),
      );

      const place: Place | null = await fetchPlace(prediction.place_id);

      return new Address(
        prediction.description, 
        primaryText,
        formatting.secondary_text,
        place
      );
    });

    callback(await Promise.all(addresses));
  });
}

export default fetchAddresses;