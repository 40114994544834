import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { Title } from "../typography/Texts";
import RequiredText from "../typography/RequiredText";

interface Props {
  title: string
}

const Header: React.FC<Props> = ({ title }) => {
  const { t } = useTranslation();
  return (
    <Box className="pdb-10" sx={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
      <Title>{title}</Title>
      <Typography variant="caption" display="block">
        <RequiredText title={t('requiredField')} />
      </Typography>
    </Box>
  )
}

export default Header;