import React from "react";
import { Card, CardContent, CardContentProps } from "@mui/material";

const Container: React.FC<CardContentProps> = ({ children, ...props }) => {
  return (
    <Card variant="outlined" className="mt-24 mr-24 ml-24 pdt-15 pdl-15 pdr-15">
      <CardContent {...props}>
        {children}
      </CardContent>
    </Card>
  )
}

export default Container;