import React from "react";
import { FormControl, FormHelperText, Select, MenuItem } from "@mui/material";

interface Option {
  label: string
  value: any
}

interface Props {
  empty?: string
  value: any
  isValid?: boolean
  helperText?: string
  options: Array<Option>
  onChange: (value: any) => void
}

const FormSelect: React.FC<Props> = ({ empty, value, isValid = true, helperText, options, onChange }) => {
  const items = options.map(option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
  return (
    <FormControl fullWidth error={!isValid}>
      <Select
        displayEmpty
        value={value}
        onChange={(e) => onChange(e.target.value)}>
        {empty && <MenuItem value=""><em>{empty}</em></MenuItem>}
        {items}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default FormSelect