import React from 'react';
import { 
  Box, 
  Grid, 
  Autocomplete, TextField,
  Typography
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { debounce } from '@mui/material/utils';

import fetchAddresses from '../api/fetchAddresses';

import Address from '../models/Address';
import Country from '../models/Country';

interface Props {
  language: string
  countries: Array<Country>
  value: Address | null
  isValid?: boolean
  helperText?: string
  placeholder?: string
  onChange: (value: Address | null) => void
}

const AddressInput: React.FC<Props> = ({ language, countries, value, isValid = true, helperText, placeholder, onChange }) => {
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly Address[]>([]);

  const fetch = React.useMemo(() => debounce(fetchAddresses, 400), []);

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(inputValue, language, countries, results => {
      if (active) {
        setOptions(value ? [value, ...results] : results);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={(option) => typeof option === 'string' ? option : option.description }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(_, value: Address | null) => {
        setOptions(value ? [value, ...options] : options);
        onChange(value);
      }}
      onInputChange={(_, value: string) => {
        setInputValue(value);
      }}
      renderInput={(params) => (<TextField {...params} fullWidth error={!isValid} helperText={helperText} placeholder={placeholder} />)}
      renderOption={(props, option: Address) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {option.primaryText.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.secondaryText}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

export default AddressInput;