import { FormikTouched, FormikErrors } from "formik";

import { State as Model } from "./slice";

class Form {
  constructor(
    private touched: FormikTouched<Model>,
    private errors: FormikErrors<Model>
  ) { };

  isTitleValid(): boolean {
    return !this.touched.title || !Boolean(this.errors.title);
  }

  titleError(): string | undefined {
    return this.touched.title ? this.errors.title : undefined;
  }

  isFirstNameValid(): boolean {
    return !this.touched.firstName || !Boolean(this.errors.firstName);
  }

  firstNameError(): string | undefined {
    return this.touched.firstName ? this.errors.firstName : undefined;
  }

  isLastNameError(): boolean {
    return !this.touched.lastName || !Boolean(this.errors.lastName);
  }

  lastNameError(): string | undefined {
    return this.touched.lastName ? this.errors.lastName : undefined;
  }

  isEmailValid(): boolean {
    return !this.touched.email || !Boolean(this.errors.email);
  }

  emailError(): string | undefined {
    return this.touched.email ? this.errors.email : undefined;
  }

  isPhoneValid(): boolean {
    return !this.touched.phone || !Boolean(this.errors.phone);
  }

  phoneError(): string | undefined {
    return this.touched.phone ? this.errors.phone : undefined;
  }
  
}

export default Form;