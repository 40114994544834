enum Title {
  MR = "mr",
  MRS = "mrs"
}

namespace Title {
  const map = new Map<string, Title>(
    Object.values(Title).map((t: Title) => ([t.toString(), t]))
  );

  export const valueOf = (str: string): Title | undefined => map.get(str);
}

export default Title;