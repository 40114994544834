import React from 'react';
import PropTypes from 'prop-types';

export default function TextArea(props) {
  const { onChange, value, concept } = props;

  const handleChange = (ev) => {
    onChange(concept, ev.target.value);
  };

  return (
    <textarea
      className='wd-100-percent hg-60 gray-border'
      onChange={handleChange}
      value={value}
    />
  )
}
