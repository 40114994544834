import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Loader } from '@googlemaps/js-api-loader';
import {
  createTheme, ThemeProvider,
  Stepper, Step, StepButton, StepLabel
} from '@mui/material';

import Luggages from "./luggages";
import Trip from "./trip";
import Contact from "./contact";
import CancellationPlans from "./cancellation";
import Summary from "./summary";

import { RestrictedDate } from "./luggages/validations";

import User from "./models/User";
import Country from "./models/Country";
import { Type as LugaggeType } from "./models/Luggage";
import { steps as initialSteps } from "./models/Step";
import Cancellation from "./models/Cancellation";

const theme = createTheme({
  palette: {
    primary: {
      main: "#5C9B4A",
      light: "#E8F5E3"
    }
  },
  typography: {
    fontSize: 24
  },
});

interface Props {
  authenticityToken: string
  user: User
  countries: Array<Country>
  typesOfLuggage: Array<LugaggeType>
  restrictedDates: Array<RestrictedDate>
  cancellations: Array<Cancellation>
}

const Booking: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const loaded = useRef(false);
  
  const [activeStep, setActiveStep] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState(initialSteps);

  const nextStep = () => {
    setSteps(steps.map((s, index) => index === activeStep ? {...s, completed: true} : s));
    setCurrentStep(activeStep + 1);
    setActiveStep(activeStep + 1);
  }

  const backStep = () => {
    setCurrentStep(activeStep);
    setActiveStep(activeStep - 1);
  }

  const isStepClickable = (index: number) => !(index === activeStep || index > currentStep);

  if (!loaded.current) {
    const loader = new Loader({
      apiKey: process.env.GOOGLE_MAP_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });

    loader.importLibrary("places").then();

    loaded.current = true;
  }

  return (
    <ThemeProvider theme={theme}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={index}>
            {!isStepClickable(index) && <StepLabel>{t(step.label)}</StepLabel>}
            {isStepClickable(index) && <StepButton onClick={() => setActiveStep(index)}>{t(step.label)}</StepButton>}
          </Step>
        ))}
      </Stepper>
      {activeStep === 0 && (
        <Luggages
          typesOfLuggage={props.typesOfLuggage}
          restrictedDates={props.restrictedDates}
          onNext={nextStep} />
      )}
      {activeStep === 1 && (
        <Trip 
          countries={props.countries}
          onNext={nextStep}
          onBack={backStep} />
      )}
      {activeStep === 2 && (
        <Contact
          onNext={nextStep}
          onBack={backStep} />
      )}
      {activeStep === 3 && (
        <CancellationPlans
          plans={props.cancellations}
          onNext={nextStep}
          onBack={backStep} />
      )}
      {activeStep === 4 && (
        <Summary
          authenticityToken={props.authenticityToken}
          user={props.user}
          typesOfLuggage={props.typesOfLuggage}
          onBack={backStep} />
      )}
    </ThemeProvider>
  )
}

export default Booking;