import React from "react";
import { Typography, TypographyProps } from "@mui/material";

export const Title: React.FC<TypographyProps> = ({ children, variant="h6", component="div", fontWeight="bold", ...props }) => {
  return <Typography {...props} variant={variant} component={component} fontWeight={fontWeight}>{children}</Typography>;
}

export const Subtitle: React.FC<TypographyProps> = ({ children, ...props }) => {
  return <Typography {...props} color="text.secondary">{children}</Typography>;
}

export const Body: React.FC<TypographyProps> = ({ children, ...props }) => {
  return <Typography {...props} variant="body1" gutterBottom>{children}</Typography>;
}

export const Pointer: React.FC<TypographyProps> = ({ color="red", component="span", ...props }) => {
  return <Typography {...props} component={component} color={color}>*</Typography>;
}

export const Error: React.FC<TypographyProps> = ({ title, component="p", color="red", fontSize="1.2857142857142856rem", ...props }) => {
  return <Typography {...props} component={component} color={color} fontSize={fontSize}>{title}</Typography>;
}