import * as yup from "yup";

import Title from "../models/Title";

const validationSchema = yup.object({
  title: yup
    .string()
    .oneOf([Title.MR, Title.MRS])
    .required("required"),
  firstName: yup
    .string()
    .required("required"),
  lastName: yup
    .string()
    .required("required"),
  email: yup
    .string()
    .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/, "invalidEmail")
    .required("required"),
  phone: yup
    .string()
    .min(10, "invalidPhoneNumber")
    .required("required"),
});

export default validationSchema;