import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";

import "../i18n.js"

import store from "./Booking/store";

import Header from "./BookingForm/Header";
import Booking from "./Booking";

function BookingPage(props) {
  const countries = props.countries.map(([code, name]) => ({code, name}));
  return (
    <div>
      <Header />
      <div className='container container_information'>
        <Provider store={store}>
          <Booking 
            authenticityToken={props.authenticityToken}
            user={props.currentUser}
            countries={countries}
            typesOfLuggage={props.luggages} 
            restrictedDates={props.restrictedDates}
            cancellations={props.cancellations} />
        </Provider>
      </div>
    </div>
  )
}

BookingPage.defaultProps = {
  currentUser: {},
  luggages: [],
  restrictedDates: [],
  countries: []
}

BookingPage.propTypes = {
  authenticityToken: PropTypes.string,
  currentUser: PropTypes.object,
  luggages: PropTypes.array,
  restrictedDates: PropTypes.array,
  countries: PropTypes.array,
  cancellations: PropTypes.array
}

export default BookingPage;