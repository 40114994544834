import React from "react";
import { ListItem, ListItemText } from '@mui/material';

interface Props {
  primary: string
  secondary: string
}

const AddressRow: React.FC<Props> = ({ primary, secondary }) => {
  return (
    <ListItem disablePadding>
      <ListItemText
        primary={primary}
        secondary={secondary}
      />
    </ListItem>
  );
};

export default AddressRow;