import React from 'react'
import NumberFormat from 'react-number-format';

export default function PhoneInput(props) {
  const { onChange, concept, value } = props;

  const handleChange = (ev) => {
    onChange(concept, ev.target.value);
  }

  return (
    <NumberFormat
      className='wd-100-percent hg-40 gray-border form-control'
      onChange={handleChange}
      value={value}
      minLength={10}
    />
  )
}
