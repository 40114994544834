enum PaymentType {
  IDEAL = 'iDeal',
  CARD = 'pinnen'
}

namespace PaymentType {
  const map = new Map<string, PaymentType>(
    Object.values(PaymentType).map((t: PaymentType) => ([t.toString(), t]))
  );
  
  export const values = () => [...map.values()];
  export const valueOf = (str: string): PaymentType | undefined => map.get(str);
}

export default PaymentType;