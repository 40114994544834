import React from "react";
import { InputLabel } from "@mui/material";
import RequiredText, { Props }  from "../typography/RequiredText";

const RequiredLabel: React.FC<Props> = (props: Props) => {
  return (
    <InputLabel sx={{ display: "flex", alignItems: "end" }}>
      <RequiredText {...props} />
    </InputLabel>
  )
}

export default RequiredLabel;