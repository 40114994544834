import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function PaymentTypeSelect({ selected, paymentTypes, onSelect, transactionId,
  onChangeTransactionId }) {
  const { t } = useTranslation();
  return (
    <div className="ml-25">
      { paymentTypes.map((paymentType) => (
        <div className="radio">
          <label>
            <input
              type="radio"
              name="payment-type"
              value={paymentType.name}
              onClick={onSelect}
              defaultChecked={selected === paymentType}
            />
            {t('with')} {paymentType.name}
          </label>
        </div>
      ))}
      { selected.isManual &&
        <div>
          <label>
            {t('transactionId')}
            <input onChange={onChangeTransactionId} value={transactionId} />
          </label>
        </div>
      }
    </div>
  )
}

PaymentTypeSelect.propTypes = {
  selected: PropTypes.object.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  transactionId: PropTypes.number.isRequired,
  onChangeTransactionId: PropTypes.func.isRequired,
}
