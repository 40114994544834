import React from 'react';
import PropTypes from 'prop-types';

const InformationTable = ({children, title}) => (
  <div className='col-xs-12'>
    <h3 className='title-3 text-center'>{title}</h3>
    <table className='wd-100-percent mt-24 regular-font-size'>
      <tbody>
        {children}
      </tbody>
    </table>
  </div>
)

InformationTable.defaultProps  = {
  title: '',
}

InformationTable.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

export default InformationTable;
