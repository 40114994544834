import React from 'react';
import PropTypes from 'prop-types';
import InputGroup from './InputGroup';
import LargeInputGroup from './LargeInputGroup';
import Input from './Input';
import Datepicker from '../Booking/luggages/Datepicker';
import moment from 'moment';
import CountrySelect from './CountrySelect';
import CheckBox from 'components/CheckBox';
import InputSelect from 'components/BookingForm/InputSelect';
import { useTranslation } from 'react-i18next';
import { DateValidator } from '../Booking/luggages/validations';

function ShippingAddressStep(props) {
  const { onChangeShippingAddress, shippingAddressData, shippingAddressDataErrors,
    travelType, countries, returnAddressData, onChangeReturnAddress,
    restrictedDates } = props;
  const returnDate = shippingAddressData['return_date']
  const departureDate = shippingAddressData['departure_date']
  const restrictedDatesArray = restrictedDates.map(restrictedDate => moment(restrictedDate.date));
  const dateValidator = new DateValidator(departureDate, returnDate, restrictedDatesArray);
  const { i18n, t } = useTranslation();

  return (
    <div>
      <div className='row pdt-24 font-black'>
        <div className='col-xs-12'>
          <p className='text-center'>{t('shippingAddressDescription')}</p>
          <table className='wd-100-percent'>
            <InputGroup label={t('departDateStep3')} error={shippingAddressDataErrors.departure_date} description={t('departureDateDescription')}>
              <Datepicker
                locale={i18n.language}
                value={departureDate ? moment(departureDate) : null}
                onChange={date => onChangeShippingAddress('departure_date', date.toDate())}
                filterDate={(date) => returnDate ? dateValidator.isMinimunDate(date) : dateValidator.isAfterToday(date)}
              />
            </InputGroup>
            { travelType != 'one_way' &&
              <InputGroup label={t('returnDateStep3')} error={shippingAddressDataErrors.return_date} description={t('returnDateDescription')}>
                <Datepicker
                  locale={i18n.language}
                  value={returnDate ? moment(returnDate) : null}
                  onChange={date => onChangeShippingAddress('return_date', date.toDate())}
                  filterDate={(date) => departureDate ? dateValidator.isMaximunDate(date) : dateValidator.isAfterToday(date)}
                />
              </InputGroup>
            }
            <InputGroup label={t('nameStep3')} error={shippingAddressDataErrors.name}>
              <Input
                value={shippingAddressData['name']}
                concept='name'
                onChange={onChangeShippingAddress}
              />
            </InputGroup>
            <InputGroup label={t('streetName')} error={shippingAddressDataErrors.street}>
              <Input
                value={shippingAddressData['street']}
                concept='street'
                onChange={onChangeShippingAddress}
              />
            </InputGroup>
            <InputGroup label={t('houseNumber')} error={shippingAddressDataErrors.address_number}>
              <Input
                value={shippingAddressData['address_number']}
                concept='address_number'
                onChange={onChangeShippingAddress}
              />
            </InputGroup>
            <InputGroup label={t('zipCode')} error={shippingAddressDataErrors.postal_code}>
              <Input
                value={shippingAddressData['postal_code']}
                concept='postal_code'
                onChange={onChangeShippingAddress}
              />
            </InputGroup>
            <InputGroup label={t('city')} error={shippingAddressDataErrors.city}>
              <Input
                value={shippingAddressData['city']}
                concept='city'
                onChange={onChangeShippingAddress}
              />
            </InputGroup>
            <InputGroup label={t('country')} error={shippingAddressDataErrors.country}>
              <CountrySelect
                countries={countries}
                value={shippingAddressData['country']}
                onChange={onChangeShippingAddress}
              />
            </InputGroup>
            { travelType == 'return' &&
              <LargeInputGroup
                label={t('returnLuggageOption')}
              >
                <InputSelect
                  value={shippingAddressData.is_the_same_return_address}
                  onChange={onChangeShippingAddress}
                  concept='is_the_same_return_address'
                />
              </LargeInputGroup>
            }
            { !shippingAddressData.is_the_same_return_address &&
              <InputGroup label={t('streetName')} error={shippingAddressDataErrors.return_street}>
                <Input
                  value={returnAddressData['street']}
                  concept='street'
                  onChange={onChangeReturnAddress}
                />
              </InputGroup>
            }
            { !shippingAddressData.is_the_same_return_address &&
              <InputGroup label={t('houseNumber')} error={shippingAddressDataErrors.return_address_number}>
                <Input
                  value={returnAddressData['address_number']}
                  concept='address_number'
                  onChange={onChangeReturnAddress}
                />
              </InputGroup>
            }
            { !shippingAddressData.is_the_same_return_address &&
              <InputGroup label={t('zipCode')} error={shippingAddressDataErrors.return_postal_code}>
                <Input
                  value={returnAddressData['postal_code']}
                  concept='postal_code'
                  onChange={onChangeReturnAddress}
                />
              </InputGroup>
            }
            { !shippingAddressData.is_the_same_return_address &&
              <InputGroup label={t('city')} error={shippingAddressDataErrors.return_city}>
                <Input
                  value={returnAddressData['city']}
                  concept='city'
                  onChange={onChangeReturnAddress}
                />
              </InputGroup>
            }
            { !shippingAddressData.is_the_same_return_address &&
              <InputGroup label={t('country')} error={shippingAddressDataErrors.return_country}>
                <CountrySelect
                  countries={countries}
                  value={returnAddressData['country']}
                  onChange={onChangeReturnAddress}
                />
              </InputGroup>
            }
          </table>
        </div>
      </div>
    </div>
  )
}

ShippingAddressStep.propTypes = {
  typesOfLuggages: PropTypes.object,
  extraLuggages: PropTypes.array,
  onClickNextStep: PropTypes.func,
  onChangeReturnAddress: PropTypes.func.isrequired,
  returnAddressData: PropTypes.object.isRequired,
}

export default ShippingAddressStep;
