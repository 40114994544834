import React from 'react';

export default function InputGroup(props) {
  const { children, label, error, description } = props;
  return(
    <tr className={`form-group ${error ? 'has-error' : ''}`}>
      <td className="wd-200 text-center font-size-2 pdb-10">{label}</td>
      <td className="pdb-10">
        { children }
        { error &&
          <span id="helpBlock2" className="help-block">{error}</span>
        }
        {
          <span className='text-left'>{description}</span>
        }
      </td>
    </tr>
  )
}
