import React from "react";
import { TypographyProps, Tooltip, SvgIcon } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Pointer } from "./Texts";

export interface Props extends TypographyProps {
  orientation?: "left" | "right"
  helpText?: string
}

const RequiredText: React.FC<Props> = ({ title, helpText, orientation = "right"}) => {
  return (
    <>
      {orientation === "left" && <Pointer className="pdr-5" />}
      {title}
      {orientation === "right" && <Pointer className="pdl-5" />}
      {helpText && (
        <Tooltip placement="right-start" title={helpText}>
          <SvgIcon className="pdl-5" sx={{ fontSize: 25 }}>
            <InfoOutlinedIcon />
          </SvgIcon>
        </Tooltip>
      )}
    </>
  )
}

export default RequiredText;