import React from 'react';
import PropTypes from 'prop-types';
import QuantitySelect from "../Booking/luggages/QuantitySelect";
import Money from 'helpers/Money';
import { useTranslation } from 'react-i18next';
function LuggageStep(props) {
  const { typesOfLuggage, extraLuggages, onChangeLuggageQuantity, selectedLuggages,
    onChangeExtraLuggage, selectedExtraLuggageId, onChangeTravelType, travelType,
    isAbleToChangeExtraLuggage } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div className='row pdt-24'>
        <div className='col-xs-12'>
          <hr></hr>
          <div className="font-size-2 font-black">
          <label className="radio-inline font-weight-bold">
            <input
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              value="one_way"
              onClick={onChangeTravelType}
              defaultChecked={travelType == 'one_way'}
            />
            {t('oneWay')}
          </label>
            <label className="radio-inline font-weight-bold">
              <input
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="return"
                onClick={onChangeTravelType}
                defaultChecked={travelType == 'return'}
              />
              {t('return')}
            </label>
          </div>
          <hr></hr>
          <table className='font-black wd-100-percent mb-25'>
            <tbody>
              { typesOfLuggage.map((typeOfLuggage) =>
                <tr key={typeOfLuggage.id}>
                  <td className="wd-130 font-size-2 mt-20"> {typeOfLuggage.name} </td>
                  <td className='text-center'>
                    <QuantitySelect
                      className={'wd-100-percent max-wd-280 hg-40 mt-20 gray-border'}
                      quantity={51}
                      value={selectedLuggages[typeOfLuggage.id]}
                      onChange={(q) => onChangeLuggageQuantity(typeOfLuggage, q)}
                    />
                  </td>
                </tr> ) }
              </tbody>
          </table>
          <table className='wd-100-percent font-black'>
            <tbody>
              <tr>
                <td className='wd-130 font-size-2'>
                  Extra Bagage
                </td>
                <td className='text-center'>
                  <select
                    className=
                      {`wd-100-percent max-wd-280 hg-40 gray-border ${!isAbleToChangeExtraLuggage ? 'not-allowed' : '' }`}
                    onChange={onChangeExtraLuggage}
                    disabled={!isAbleToChangeExtraLuggage}
                    value={selectedExtraLuggageId}
                  >
                    <option value={''}>-</option>
                    { extraLuggages.map((extraLuggage) =>
                      <option value={extraLuggage.id} key={extraLuggage.id}>
                        {`${extraLuggage.name} - ${Money.fromCents(extraLuggage.price_cents)}`}
                      </option> ) }
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

LuggageStep.propTypes = {
  typesOfLuggages: PropTypes.object,
  extraLuggages: PropTypes.array,
  onChangeExtraLuggage: PropTypes.func.isRequired,
  selectedExtraLuggageId: PropTypes.string.isRequired,
}

export default LuggageStep;
