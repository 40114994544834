import Joi from 'joi-browser';

export default function validate(object, schema, objectName) {
  const result = Joi.validate(object, schema, { abortEarly: false });
  let errorObject = {};

  if (result.error) {
    result.error.details.forEach((detail) => {
      if (!Object.keys(errorObject).includes(detail.context.keys)) {
        errorObject[detail.context.key] = detail.message;
      };
    });
  };

  const isValid = Object.keys(errorObject).length === 0;

  return {
    isValid,
    errorObject,
    objectName,
  };
}
