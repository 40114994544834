import React from "react";
import { TextField, TextFieldProps, TextareaAutosize } from "@mui/material";

const Textarea: React.FC<TextFieldProps> = ({minRows = 3, ...props}) => {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: TextareaAutosize,
        inputProps: {
          minRows,
        },
      }}
      variant="outlined"
    />
  );
}

export default Textarea;