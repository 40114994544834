import React from "react";
import { IMaskInput, IMask } from "react-imask";
import { TextField } from "@mui/material";

const PHONE_NUMBER_MASK = "(#00) 000-0000";
const PHONE_NUMBER_DEF = { '#': /[1-9]/, };

export const phoneNumberFormat = (value: string): string => {
  const phoneMask = IMask.createMask({ mask: PHONE_NUMBER_MASK, definitions: PHONE_NUMBER_DEF });
  phoneMask.resolve(value);
  return phoneMask.value;
}

interface InputProps {
  name: string
  onChange: (event: { target: { name: string; value: string } }) => void;
}

const PhoneMask = React.forwardRef<HTMLInputElement, InputProps>(
  function PhoneMask(props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={PHONE_NUMBER_MASK}
        definitions={PHONE_NUMBER_DEF}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

interface Props {
  value: string
  isValid?: boolean
  helperText?: string
  onChange: (value: string) => void
}

const PhoneInput: React.FC<Props> = ({ value, isValid = true, helperText, onChange }) => {
  return (
    <TextField
      fullWidth
      value={value}
      error={!isValid}
      helperText={helperText}
      placeholder="(555) 555-5555"
      onChange={(e) => onChange(e.target.value.replace(/[() -]/g, ''))}
      InputProps={{ inputComponent: PhoneMask as any }} />
  );
}

export default PhoneInput;