import Address from "../models/Address";
import Partner from "../models/Partner";

import axios from "./axios";

const fetchPartners = async (address: Address): Promise<Partner[] | null> => {
  try {
    const { lat, lng } = address.place;

    const response = await axios.get<Partner[]>("/partners.json", { params: { lat, lng } });
    
    return response.data || [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export default fetchPartners;