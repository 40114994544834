import React from "react";
import { useTranslation } from "react-i18next";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import { useField } from "formik";

interface Props {
  format?: string
  name: string
  locale: string
  referenceDate?: Moment | null
  shouldDisableDate: (value: Moment) => boolean
}

const Datepicker: React.FC<Props> = ({ locale, format, name, referenceDate, shouldDisableDate }) => {
  const [field, meta, helpers] = useField<Date | null>(name);
  const { t } = useTranslation();
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
      <DatePicker
        format={format}
        value={field.value ? moment(field.value) : null}
        onChange={value => helpers.setValue(value?.toDate())}
        shouldDisableDate={shouldDisableDate}
        referenceDate={referenceDate}
        slotProps={{
          textField: {
            error: meta.touched && Boolean(meta.error),
            helperText: meta.touched && meta.error && t(meta.error)
          }
        }}
      />
    </LocalizationProvider>
  )
}

export default Datepicker;