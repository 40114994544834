import { createSlice } from "@reduxjs/toolkit";

import Address from "../models/Address";
import Partner from "../models/Partner";

export interface State {
  collect: Address | null
  collectPartner: Partner | null
  collectPartners: Array<Partner>

  deliver: Address | null
  deliveryPartner: Partner | null
  deliveryPartners: Array<Partner>

  isDeliverSameAsPickup: boolean

  pickup: Address | null
  pickupPartner: Partner |  null
  pickupPartners: Array<Partner>

  isCollectSameAsBack: boolean

  back: Address | null
}

const initialState: State = {
  collect: null,
  collectPartner: null,
  collectPartners: [],
  deliver: null,
  deliveryPartner: null,
  deliveryPartners: [],
  isDeliverSameAsPickup: true,
  pickup: null,
  pickupPartner: null,
  pickupPartners: [],
  isCollectSameAsBack: true,
  back: null
}

const tripSlice = createSlice({
  name: "tripSlice",
  initialState: initialState,
  reducers: {
    setCollect(state, action) {
      state.collect = action.payload;
    },
    setCollectPartner(state, action) {
      state.collectPartner = action.payload;
    },
    setCollectPartners(state, action) {
      state.collectPartners = action.payload;
    },
    setDeliver(state, action) {
      state.deliver = action.payload;
    },
    setDeliveryPartner(state, action) {
      state.deliveryPartner = action.payload;
    },
    setDeliveryPartners(state, action) {
      state.deliveryPartners = action.payload;
    },
    isDeliverSameAsPickup(state, action) {
      state.isDeliverSameAsPickup = action.payload;
    },
    setPickup(state, action) {
      state.pickup = action.payload;
    },
    setPickupPartner(state, action) {
      state.pickupPartner = action.payload;
    },
    setPickupPartners(state, action) {
      state.pickupPartners = action.payload;
    },
    isCollectSameAsBack(state, action) {
      state.isCollectSameAsBack = action.payload;
    },
    setBack(state, action) {
      state.back = action.payload;
    }
  }
});

export const { 
  setCollect, setCollectPartner, setCollectPartners,
  setDeliver, setDeliveryPartner, setDeliveryPartners,
  isDeliverSameAsPickup,
  setPickup, setPickupPartner, setPickupPartners,
  isCollectSameAsBack,
  setBack
} = tripSlice.actions
export default tripSlice.reducer