import React from "react";

import FormSelect from "../shared/FormSelect";

interface Props {
  quantity: number
  empty: string
  value: number | string
  isValid?: boolean
  helperText?: string
  onChange: (value: number | string) => void
}

const QuantitySelect: React.FC<Props> = ({ quantity, empty, value, isValid, helperText, onChange }) => {
  let options = Array.from({ length: quantity }, (_, i) => {
    let num = i + 1;
    return {
      label: num.toString(), 
      value: num
    }
  });
  return (
    <FormSelect
      empty={empty}
      value={value}
      isValid={isValid}
      helperText={helperText}
      options={options}
      onChange={onChange} />
  )
}

export default QuantitySelect;