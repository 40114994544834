import * as yup from "yup";

import TripType from "../models/TripType";
import Address from "../models/Address";
import Partner from "../models/Partner";

const validationSchema = yup.object({
  tripType: yup
    .string()
    .oneOf([TripType.ONE_WAY, TripType.RETURN]),
  collect: yup
    .object<Address>()
    .required("required"),
  deliver: yup
    .object<Address>()
    .required("required"),
  deliveryPartners: yup
    .array<Partner>(),
  deliveryPartner: yup
    .object<Partner>()
    .when('deliveryPartners', (values: Array<Array<Partner>>, schema: yup.ObjectSchema<{}>) => (
      values[0].length > 0 ? schema.required("requiredPartner") : schema.notRequired()
    )),
  isDeliverSameAsPickup: yup
    .boolean()
    .when('tripType', (values: Array<TripType>, schema: yup.BooleanSchema) => (
      values[0] === TripType.ONE_WAY ? schema.notRequired() : schema.required("required")
    )),
  pickup: yup
    .object<Address>()
    .when('isDeliverSameAsPickup', (values: Array<boolean>, schema: yup.ObjectSchema<{}>) => (
      values[0] === true ? schema.notRequired() : schema.required("required")
    )),
  pickupPartners: yup
    .array<Partner>(),
  pickupPartner: yup
    .object<Partner>()
    .when(['isDeliverSameAsPickup', 'pickupPartners'], (values: any[], schema: yup.ObjectSchema<{}>) => {
      const isDeliverSameAsPickup: boolean = values[0];
      const pickupPartners: Array<Partner> = values[1];
      return !isDeliverSameAsPickup && pickupPartners.length > 0 ? schema.required("requiredPartner") : schema.notRequired()
    }),
  isCollectSameAsBack: yup
    .boolean()
    .when('tripType', (values: Array<TripType>, schema: yup.BooleanSchema) => (
      values[0] === TripType.ONE_WAY ? schema.notRequired() : schema.required("required")
    )),
  back: yup
    .object<Address>()
    .when('isCollectSameAsBack', (values: Array<boolean>, schema: yup.ObjectSchema<{}>) => (
      values[0] === true ? schema.notRequired() : schema.required("required")
    )),
});

export default validationSchema;