import Place from "./Place"
class Address {
  constructor(
    public description: string,
    public primaryText: Array<HighlightedText>,
    public secondaryText: string,
    public place: Place | null
  ) { };
  

  fullText(): string {
    return `${this.primaryText.map(t => t.text).join('')} ${this.secondaryText}`;
  }
}

export interface HighlightedText {
  text: string
  highlight: boolean
}

export default Address;