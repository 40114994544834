import React from "react";
import { SvgIconComponent } from '@mui/icons-material';
import { ListItem, ListItemIcon, ListItemText, SvgIcon } from '@mui/material';


interface Props {
  leftIcon?: SvgIconComponent
  text: string
}

const InfoRow: React.FC<Props> = ({ leftIcon, text }) => {
  return (
    <ListItem sx={{paddingX: 0}}>
      {leftIcon && (
        <ListItemIcon>
        <SvgIcon component={leftIcon} />
      </ListItemIcon>
      )}
      <ListItemText
        primary={text}
      />
    </ListItem>
  );
};

export default InfoRow;
