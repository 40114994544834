import { createSlice } from "@reduxjs/toolkit";

import Luggage from "../models/Luggage";
import TripType from "../models/TripType";

export interface State {
  tripType: TripType
  departureDate: string | null
  returnDate: string | null
  lugagges: Array<Luggage>
}

const initialState: State = {
  tripType: TripType.ONE_WAY,
  departureDate: null,
  returnDate: null,
  lugagges: [{ typeId: 1, quantity: "" }, { typeId: 2, quantity: "" }]
}

const luggagesSlice = createSlice({
  name: "luggagesSlice",
  initialState: initialState,
  reducers: {
    setTripType(state, action) {
      state.tripType = TripType.valueOf(action.payload);
      state.returnDate = null;
    },
    setDepartureDate(state, action) {
      state.departureDate = action.payload;
    },
    setReturnDate(state, action) {
      state.returnDate = action.payload;
    },
    setLugagges(state, action) {
      state.lugagges = action.payload;
    }
  }
});

export const { setTripType, setDepartureDate, setReturnDate, setLugagges } = luggagesSlice.actions
export default luggagesSlice.reducer