import { configureStore, combineReducers } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { thunk } from "redux-thunk";
import { useDispatch } from "react-redux";

import luggagesReducer from "./luggages/slice";
import tripReducer from "./trip/slice";
import contactReducer from "./contact/slice";
import cancellationReducer from "./cancellation/slice";
import summaryReducer from "./summary/slice";

const rootReducer = combineReducers({
  step1: luggagesReducer,
  step2: tripReducer,
  step3: contactReducer,
  step4: cancellationReducer,
  step5: summaryReducer
});

export type State = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ serializableCheck: false }).prepend(thunk, logger),
})

export default store;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;