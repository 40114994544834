import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  List, ListItem, ListItemText, ListItemIcon,
  Radio
} from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Title, Pointer, Error } from "../typography/Texts";

import Partner from "../models/Partner";

interface ItemProps {
  partner: Partner
  selected: boolean
  onChecked: () => void
}

const PartnerItem: React.FC<ItemProps> = ({ partner, selected, onChecked }) => (
  <ListItem disablePadding key={partner.id}>
    <ListItemIcon>
      <Radio name="partner" disableRipple checked={selected} onChange={onChecked}/>
    </ListItemIcon>
    <ListItemText primary={partner.name} secondary={partner.address} />
  </ListItem>
)

interface Props {
  partners: Array<Partner>
  selected: Partner | null
  isValid?: boolean
  helperText?: string
  onChecked: (partner: Partner) => void
}

const Partners: React.FC<Props> = ({ partners, selected, isValid = true, helperText, onChecked }) => {
  const { t } = useTranslation();
  return (
    <Box marginTop={3} padding={2} sx={{ bgcolor: 'primary.light' }}>
      <Box sx={{ display: "flex" }}>
        <Title>{t('partnersTitle')}</Title>
        <Pointer className="pdl-5" />
      </Box>
      <span>{t('partnersDescription')}</span>
      <List>
        { partners.map(p => (<PartnerItem key={p.id} partner={p} selected={p === selected} onChecked={() => onChecked(p)} />)) }
      </List>
      {!isValid && (
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <WarningAmberIcon htmlColor="red" fontSize="small" className="mr-5" />
          <Error title={helperText} />
        </Box>
      )}
    </Box>
  )
}

export default Partners;