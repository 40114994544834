import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";

interface Props {
  onNext?: (e: any) => void
  onBack?: () => void
  onFinish?: () => void
}

const StepperActions: React.FC<Props> = ({ onNext, onBack, onFinish }) => {
  const { t } = useTranslation();
  return (
    <Box className="pd-20" sx={{ display: "flex", flexDirection: "row" }}>
      { onBack && (
        <Button variant="outlined" onClick={onBack}>
          {t('backButton')}
        </Button>
      )}
      <Box sx={{ flex: '1 1 auto' }} />
      { onNext && (
        <Button variant="contained" onClick={onNext}>
          {t('nextButton')}
        </Button>
      )}
      { onFinish && (
        <Button variant="contained" onClick={onFinish}>
          {t('payButton')}
        </Button>
      )}
    </Box>
  )
}

export default StepperActions;