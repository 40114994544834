import React from "react";
import { useTranslation } from "react-i18next";

import FormSelect from "../shared/FormSelect";

import PaymentType from "../models/PaymentType";

interface Props {
  value: string
  onChange: (value: string) => void
}

const PaymentTypeSelect: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const options = PaymentType.values().map(type => ({ value: type, label: `${t('with')} ${type}` }));
  return (
    <FormSelect
      value={value}
      options={options}
      onChange={onChange} />
  )
}

export default PaymentTypeSelect;