import Translation from "./Translation"

enum AmountType {
  fixed = 0,
  percentaje = 1
}

namespace AmountType {  
  export const symbol = (type: number): string | undefined => {
    switch (type) {
      case AmountType.fixed: return "$";
      case AmountType.percentaje: return "%";
      default: return undefined;
    }
  }
}

interface Feature {
  name: string
  included: boolean
  translations: Array<Translation>
}

interface Cancellation {
  id: number
  title: string
  amount: number
  amount_type: AmountType
  terms_and_conditions_url: string | null
  features: Array<Feature>
}

namespace Cancellation {
  export const price = (base: number, bicyclesQuantity: number, plan: Cancellation): number => {
    switch (plan.amount_type) {
      case AmountType.percentaje: return base * (plan.amount / 100);
      case AmountType.fixed: return (bicyclesQuantity > 0 ? bicyclesQuantity : 1) * plan.amount * 100;
      default: return 0;
    }
  }
}

export { AmountType };
export default Cancellation;