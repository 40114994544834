import React from "react";
import { useTranslation } from "react-i18next";
import {
  Card, CardActionArea, CardContent, CardActions,
  List, ListItem, ListItemIcon, ListItemText,
  Button,
  Link,
  useTheme
} from "@mui/material";
import { Check, Close } from '@mui/icons-material';

import { Title, Subtitle } from "../typography/Texts";

import Cancellation, { AmountType } from "../models/Cancellation";

interface Props {
  isSelected: boolean
  plan: Cancellation
  onSelect: (plan: Cancellation | null) => void
}

const PlanCard: React.FC<Props> = ({ isSelected, plan, onSelect }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const border = isSelected ? `1px solid ${theme.palette.primary.main}` : 'inherit';
  const symbol = "EUR";
  return (
    <Card sx={{ minWidth: 275, border: border }}>
      <CardActionArea disableRipple onClick={() => onSelect(isSelected ? null : plan)}>
        <CardContent>
          <Subtitle sx={{ fontSize: 14 }} gutterBottom>
            {plan.title}
          </Subtitle>
          <Title variant="h5" component="div">
            {plan.amount}{",00"} {symbol}
          </Title>
          <Subtitle sx={{ mb: 1.5 }}>
            {t('cancellationPlanDescription')}
          </Subtitle>
          <List>
            {plan.features.map(feature => {
              return (
                <ListItem key={feature.name}>
                  <ListItemIcon>
                    {feature.included ? <Check color="primary" /> : <Close color="error" />}
                  </ListItemIcon>
                  <ListItemText primary={feature.name} />
                </ListItem>
              )
            })}
          </List>
          {plan.terms_and_conditions_url && (
            <Link 
              underline="hover"
              target="_blank"
              rel="noopener"
              href={plan.terms_and_conditions_url}>
                {t('termsAndConditions')}
            </Link>
          )}
        </CardContent>
      </CardActionArea>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant={isSelected ? "text" : "contained"} onClick={() => onSelect(isSelected ? null : plan)}>
          {isSelected ? "Deselect" : "Select"}
        </Button>
      </CardActions>
    </Card>
  )
}

export default PlanCard;