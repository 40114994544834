import moment from 'moment';

const toString = (momentDate) => (
  moment(momentDate, 'DD-MM-yyyy').format('DD-MM-yyyy')
)

const DateHelper = {
  toString,
}

export default DateHelper;
