interface Step {
  label: string
  completed: boolean
}

export const steps: Array<Step> = [
  {
    label: 'luggageStep',
    completed: false
  },
  {
    label: 'tripInformationStep',
    completed: false
  },
  {
    label: 'personalInformationStep',
    completed: false
  },
  {
    label: 'cancellationStep',
    completed: false
  },
  {
    label: 'confirmationStep',
    completed: false
  }
];

export default Step;