import React from 'react'
import { useTranslation } from 'react-i18next';


export default function TermsCheckbox(props) {
  const { value, onChange } = props;
  const { t } = useTranslation();
  const handleClick = () => {
    onChange('terms_and_conditions', !value);
  }

  return(
    <div className="checkbox">
      <label>
        <input type="checkbox" defaultChecked={value} onClick={handleClick} />
        {t('AgreeTermsAndConditions')} <a href='/terms-and-conditions' target="_blank">{t('termsAndConditionsStep4')}</a>
      </label>
    </div>
  )
}
