import React from 'react';
import PropTypes from 'prop-types';

const InformationRow = ({description, value}) => (
  <tr>
    <td width='280px'><b>{description}:</b></td>
    <td>{value}</td>
  </tr>
)

InformationRow.propTypes = {
  description: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
}

export default InformationRow;
