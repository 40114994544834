import React from 'react';
import PropTypes from 'prop-types';

export default function CountrySelect(props) {
  const { countries, value, onChange } = props;

  const handleChange = (ev) => {
    onChange('country', ev.target.value);
  };

  return (
    <select value={value} className='wd-100-percent hg-60 gray-border' onChange={handleChange}>
      { countries.map((country) =>
        <option value={country[1]} key={country[0]}>{country[1]}</option>)}
    </select>
  )
}

CountrySelect.propTypes = {
  countries: PropTypes.array.isRequired,
}
