import { FormikTouched, FormikErrors } from "formik";

import { State as Model } from "./slice";
import Luggage from "../models/Luggage";

class Form {
  constructor(
    private touched: FormikTouched<Model>,
    private errors: FormikErrors<Model>
  ) { };

  isLugaggeTypeValid(index: number): boolean {
    const hasError = this.touched.lugagges && this.touched.lugagges[index]?.typeId && this.errors.lugagges;
    if (hasError) {
      const error: string | FormikErrors<Luggage> | null = this.errors.lugagges[index];
      return !Boolean(typeof error === 'string' || error?.typeId);
    }

    return true;
  }

  lugaggeTypeError(index: number): string | undefined {
    if (!this.isLugaggeTypeValid(index)) {
      const error: string | FormikErrors<Luggage> = this.errors.lugagges[index];
      return typeof error === 'string' ? error : error.typeId;
    }
    return undefined;
  }

  isQuantityValid(index: number): boolean {
    const hasError = this.touched.lugagges && this.touched.lugagges[index]?.typeId && this.errors.lugagges;
    if (hasError) {
      const error: string | FormikErrors<Luggage> | null = this.errors.lugagges[index];
      return !Boolean(typeof error === 'string' || error?.quantity);
    }

    return true;
  }

  quantityError(index: number): string | undefined {
    if (!this.isQuantityValid(index)) {
      const error: string | FormikErrors<Luggage> = this.errors.lugagges[index];
      return typeof error === 'string' ? error : error.quantity;
    }
    return undefined;
  }
  
}

export default Form;