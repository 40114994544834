import React from "react";
import { useTranslation } from "react-i18next";

import FormSelect from "../shared/FormSelect";

import Title from "../models/Title";

interface Props {
  empty: string
  value: string
  isValid?: boolean
  helperText?: string
  onChange: (value: string) => void
}

const TitleSelect: React.FC<Props> = ({ empty , value, isValid, helperText, onChange }) => {
  const { t } = useTranslation();
  const options = [Title.MR, Title.MRS].map(title => ({label: t(title), value: title}));
  return (
    <FormSelect
      empty={empty}
      value={value}
      isValid={isValid}
      helperText={helperText}
      options={options}
      onChange={onChange} />
  )
}

export default TitleSelect;