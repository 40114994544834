import React from 'react';
import { useTranslation } from 'react-i18next';
import englishIcon from 'images/english.png';
import SpanishIcon from 'images/spain.png';
import DutchIcon from 'images/netherlands.png';
import logoIcon from 'images/logo.jpg';
import i18n from 'i18next';

function Header() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="left"></div>
      <a href="/"><img height="140" src={logoIcon} /></a>
      <div className="right mt-10 nav_menu">
        <ul className="left nav navbar-nav">
          <li>
            <a href="/" className="uppercase">{t('backHome')}</a>
          </li>
        </ul>
        {/*<a className='mr-4' onClick={() => i18n.changeLanguage('en')} ><img src={englishIcon} className='wd-30'/> </a>*/}
        <a className='mr-7' onClick={() => i18n.changeLanguage('nl')} ><img src={DutchIcon} className='mt-10 wd-30' /></a>
        <a className='mr-7' onClick={() => i18n.changeLanguage('es')} ><img src={SpanishIcon} className='mt-10 wd-30' /></a>
      </div>
    </div>
  )
}


export default Header;
