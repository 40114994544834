import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';


export default function StepButtons(props) {
  const { onClickNextStep, currentStep, onClickBackStep, steps, onClickPay,
          isValidLuggageStep } = props;

  const { t } = useTranslation();


  return(
    <div className='row'>
      <div className='col-md-12 text-center mt-25'>
        { currentStep != 1 &&
          <a className='btn btn-default btn-back' onClick={onClickBackStep}>
            {t('backButton')}
          </a>
        }
        { !(currentStep === steps.length) &&
            <button
              className='btn-confirmation ml-5'
              onClick={onClickNextStep}
              disabled={!isValidLuggageStep}
            >
            {t('nextButton')}
            </button>
        }
        { currentStep === steps.length &&
          <a className='btn-confirmation ml-5' onClick={onClickPay}>
            {t('payButton')}
          </a>
        }
      </div>
    </div>
  )
}

StepButtons.propTypes = {
  onClickNextStep: PropTypes.func.isRequired,
  onClickBackStep: PropTypes.func.isRequired,
  onClickPay: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired,
}
