import { createSlice } from "@reduxjs/toolkit";

import Cancellation from "../models/Cancellation";

export interface State {
  noPlan: boolean
  plan: Cancellation | null
}

const initialState: State = {
  noPlan: false,
  plan: null
}

const cancellationSlice = createSlice({
  name: "cancellationSlice",
  initialState: initialState,
  reducers: {
    setNoPlan(state, action) {
      state.noPlan = action.payload;
      state.plan = null;
    },
    setPlan(state, action) {
      state.plan = action.payload;
      state.noPlan = false;
    }
  }
});

export const { setNoPlan, setPlan } = cancellationSlice.actions
export default cancellationSlice.reducer