import { FormikTouched, FormikErrors } from "formik";

import { State as Model } from "./slice";

class Form {
  constructor(
    private touched: FormikTouched<Model>,
    private errors: FormikErrors<Model>
  ) { };

  isCollectValid(): boolean {
    return !this.touched.collect || !Boolean(this.errors.collect);
  }

  collectError(): string | undefined {
    return this.touched.collect ? this.errors.collect as string : undefined;
  }

  isCollectPartnerValid(): boolean {
    return !this.touched.collectPartner || !Boolean(this.errors.collectPartner);
  }

  collectPartnerError(): string | undefined {
    return this.touched.collectPartner ? this.errors.collectPartner as string : undefined;
  }
  
  isDeliverValid(): boolean {
    return !this.touched.deliver || !Boolean(this.errors.deliver);
  }

  deliverError(): string | undefined {
    return this.touched.deliver ? this.errors.deliver as string : undefined;
  }

  isDeliveryPartnerValid(): boolean {
    return !this.touched.deliveryPartner || !Boolean(this.errors.deliveryPartner);
  }

  deliveryPartnerError(): string | undefined {
    return this.touched.deliveryPartner ? this.errors.deliveryPartner as string : undefined;
  }

  isPickupValid(): boolean {
    return !this.touched.pickup || !Boolean(this.errors.pickup);
  }

  pickupError(): string | undefined {
    return this.touched.pickup ? this.errors.pickup as string : undefined;
  }

  isBackValid(): boolean {
    return !this.touched.back || !Boolean(this.errors.back);
  }

  backError(): string | undefined {
    return this.touched.back ? this.errors.back as string : undefined;
  }
}

export default Form;