import React from 'react'; import PropTypes from 'prop-types';
import InformationTable from './InformationTable';
import InformationRow from './InformationRow';
import DateHelper from 'helpers/DateHelper';
import PaymentTypeSelect from './PaymentTypeSelect';
import { useTranslation } from 'react-i18next';

const CheckYourDataStep = ({contactData, shippingAddressData, deliveryAddressData, pricing,
  selectedLuggages, typesOfLuggage, extraLuggages, extraLuggage, currentUser,
  paymentTypes, selectedPaymentType, onSelectPaymentType, transactionId, onChangeTransactionId,
  travelType, returnAddressData, pickupAddressData }) => {
    const returnAddress =
      shippingAddressData.is_the_same_return_address ? shippingAddressData : returnAddressData;
    const pickupAddress =
      deliveryAddressData.is_the_same_pickup_address ? deliveryAddressData : pickupAddressData;
    const { t } = useTranslation();
    return (
      <div>
        <div className='row pdt-24 font-black'>
          <div className='col-xs-12'>
            <InformationTable title={t('personalInformation')}>
              <InformationRow description={t('titleStep2')} value={contactData.title} />
              <InformationRow description={t('firstNameStep2')} value={contactData.first_name} />
              <InformationRow description={t('lastNameStep2')} value={contactData.last_name} />
              <InformationRow description={t('phoneNumberStep2')} value={contactData.phone} />
              <InformationRow description={t('emailStep2')} value={contactData.email} />
            </InformationTable>
            <InformationTable title={t('shippingAddress')}>
              <InformationRow
                description={t('departDateStep3')}
                value={DateHelper.toString(shippingAddressData.departure_date)}
              />
              { travelType != 'one_way' &&
                <InformationRow
                  description={t('returnDateStep3')}
                  value={shippingAddressData.return_date ? DateHelper.toString(shippingAddressData.return_date) : undefined}
                />
              }
              <InformationRow description={t('streetName')} value={shippingAddressData.street} />
              <InformationRow description={t('houseNumber')} value={shippingAddressData.address_number} />
              <InformationRow description={t('zipCode')} value={shippingAddressData.postal_code} />
              <InformationRow description={t('city')} value={shippingAddressData.city} />
              <InformationRow description={t('country')} value={shippingAddressData.country} />
            </InformationTable>
            { travelType === 'return' &&
              <InformationTable title={t('returnAddress')}>
                <InformationRow description={t('streetName')} value={returnAddress.street} />
                <InformationRow description={t('houseNumber')} value={returnAddress.address_number} />
                <InformationRow description={t('zipCode')} value={returnAddress.postal_code} />
                <InformationRow description={t('city')} value={returnAddress.city} />
                <InformationRow description={t('country')} value={returnAddress.country} />
              </InformationTable>
            }
            <InformationTable title={t('deliveryAddressOfTheAccommodation')}>
              <InformationRow description={t('accommodationNameStep4')} value={deliveryAddressData.accommodatie} />
              <InformationRow description={t('streetName')} value={deliveryAddressData.street} />
              <InformationRow description={t('houseNumber')} value={deliveryAddressData.address_number} />
              <InformationRow description={t('zipCode')} value={deliveryAddressData.postal_code} />
              <InformationRow description={t('city')} value={deliveryAddressData.city} />
              <InformationRow description={t('country')} value={deliveryAddressData.country} />
              <InformationRow description={t('phoneNumberStep2')} value={deliveryAddressData.phone} />
            </InformationTable>
            { travelType === 'return' &&
              <InformationTable title={t('pickupAddress')}>
                <InformationRow description={t('accommodationNameStep4')} value={pickupAddress.accommodatie} />
                <InformationRow description={t('streetName')} value={pickupAddress.street} />
                <InformationRow description={t('houseNumber')} value={pickupAddress.address_number} />
                <InformationRow description={t('zipCode')} value={pickupAddress.postal_code} />
                <InformationRow description={t('city')} value={pickupAddress.city} />
                <InformationRow description={t('country')} value={pickupAddress.country} />
                <InformationRow description={t('phoneNumberStep2')} value={pickupAddress.phone} />
              </InformationTable>
            }
            <InformationTable title={t('luggageStep')}>
              { Object.keys(selectedLuggages).map((luggageId) => (
                  selectedLuggages[luggageId] > 0 &&
                <InformationRow
                  description={typesOfLuggage.filter(typeOfLuggage => typeOfLuggage.id == luggageId)[0].name}
                  key={luggageId}
                  value={selectedLuggages[luggageId]}
                />))}
                { extraLuggage &&
                  <InformationRow
                    description={t('extraLuggage')}
                    value={extraLuggages.filter(el => el.id == extraLuggage)[0].name}
                  />
                }
            </InformationTable>
            <InformationTable title={t('totalAmount')}>
              <InformationRow description={t('PriceWithoutTaxes')} value={pricing.priceWithoutVAT} />
              <InformationRow description={t('tax')} value={pricing.VAT} />
              <InformationRow description={t('subtotal')} value={pricing.total} />
            </InformationTable>
            { currentUser && currentUser.role === 'admin' &&
                <PaymentTypeSelect
                  paymentTypes={paymentTypes}
                  selected={selectedPaymentType}
                  onSelect={onSelectPaymentType}
                  onChangeTransactionId={onChangeTransactionId}
                  transactionId={transactionId}
                />
            }
          </div>
        </div>
      </div>
    )
};

CheckYourDataStep.propTypes = {
  contactData: PropTypes.object.isRequired,
  shippingAddressData: PropTypes.object.isRequired,
  deliveryAddressData: PropTypes.object.isRequired,
  selectedLuggages: PropTypes.object.isRequired,
  pricing: PropTypes.object.isRequired,
  typesOfLuggage: PropTypes.array.isRequired,
  extraLuggages: PropTypes.array.isRequired,
  extraLuggage: PropTypes.node.isRequired,
  currentUser: PropTypes.object.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  selectedPaymentType: PropTypes.object.isRequired,
  onSelectPaymentType: PropTypes.func.isRequired,
  onChangeTransactionId: PropTypes.func.isRequired,
  transactionId: PropTypes.node.isRequired,
}

export default CheckYourDataStep;
