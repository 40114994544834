import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ReturnAddressInputSelect(props) {
  const { onChange, value, concept } = props;

  const handleChangeTrue = () => onChange(concept, true);
  const handleChangeFalse = () => onChange(concept, false);
  const { t } = useTranslation();

  return (
    <div className="font-size-2 font-black display-inline ml-5">
      <label class="radio-inline">
        <input
          type="radio"
          name="inlineRadioOptions"
          id="inlineRadio1"
          value="return"
          onClick={handleChangeTrue}
          defaultChecked={value}
        />
        {t('yes')}
      </label>
      <label class="radio-inline">
        <input
          type="radio"
          name="inlineRadioOptions"
          id="inlineRadio2"
          value="one_way"
          onClick={handleChangeFalse}
          defaultChecked={!value}
        />
        {t('no')}
      </label>
    </div>
  )
}
