import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Step = ({step, stepTitles, onClick, isSelected, isSelectable}) => {
  const activeClass = isSelected ? 'active' : '';
  const { t } = useTranslation();
  const disbaledClass = isSelectable ? '' : 'disabled';

  const onChange = () => {
    if (isSelectable) {
      onClick(parseInt(step));
    }
  }

  return (

    <td
      className={`step ml-5 pdb-25 pdt-10 ${activeClass} ${disbaledClass}`}
      onClick={onChange}
    >
      <h5> {t('step')} {step} </h5>
      <span className="fs-16 bold step-description">{t(stepTitles[step])}</span>
    </td>
  )
}

Step.defaultProps = {
  isSelected: false,
}

Step.propTypes = {
  step: PropTypes.node.isRequired,
  stepTitles: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  isSelectable: PropTypes.bool.isRequired,
}

export default Step;
