import React from 'react';
import PropTypes from 'prop-types';
import Step from './Step';
import './style.scss';

const StepIndicator = ({currentStep, stepTitles, onChange, validSteps}) => (
  <div className="row">
    <table className="wd-100-percent">
      <tbody>
        <tr>
          {Object.keys(stepTitles).map((key) => (
            <Step
              key={key}
              step={key}
              stepTitles={stepTitles}
              onClick={onChange}
              isSelected={currentStep == key}
              isSelectable={validSteps.filter((validStep) => (validStep.stepKey == key))[0].isValid}
            />
          ))}
        </tr>
      </tbody>
    </table>
  </div>
);

StepIndicator.propTypes = {
  currentStep: PropTypes.number.isRequired,
  stepTitles: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  validSteps: PropTypes.array.isRequired,
}

export default StepIndicator;
