import * as yup from "yup";

import TripType from "../models/TripType";
import Luggage from "../models/Luggage";

const validationSchema = yup.object({
  tripType: yup
    .string()
    .oneOf([TripType.ONE_WAY, TripType.RETURN]),
  departureDate: yup
    .date().typeError("invalidDate")
    .required("required")
    .when("tripType", {
      is: (value: TripType) => value === TripType.RETURN,
      then: (schema: yup.DateSchema<Date>) => {
        return schema.test("is-less", "departureAfterReturn", (value: Date, context: yup.TestContext) => {
          const { returnDate } = context.parent;
          if (returnDate && value) {
            const start = new Date(value);
            const end = new Date(returnDate);
            end.setDate(end.getDate() - 1);
            return start <= end;
          }
          return true;
        })
      }
    }),
  returnDate: yup
    .date().typeError("invalidDate")
    .when("tripType", {
      is: (value: TripType) => value === TripType.RETURN,
      then: (schema: yup.DateSchema<Date>) => {
        return schema.required("required")
          .test("is-greater", "returnBeforeDeparture", (value: Date, context: yup.TestContext) => {
            const { departureDate } = context.parent;
            if (departureDate && value) {
              const start = new Date(departureDate);
              const end = new Date(value);
              start.setDate(start.getDate() + 1);
              return end >= start;
            }
            return true;
          })
      },
      otherwise: (schema: yup.DateSchema<Date>) => schema.notRequired()
    }),
  lugagges: yup
    .array<Luggage>()
    .of(yup.object({
      typeId: yup.number().notRequired(),
      quantity: yup.number().min(1).max(50).notRequired()
    }))
    .min(1)
    .test("firstItemIsRequired", (values) => {
      const first = values[0];

      if (!first) {
        return false;
      }

      const errors: Array<yup.ValidationError> = [];

      if (!first.typeId) {
        errors.push(new yup.ValidationError('required', null, 'lugagges[0].typeId'));
      }

      if (!first.quantity) {
        errors.push(new yup.ValidationError('required', null, 'lugagges[0].quantity'));
      }

      if (errors.length > 0) {
        return new yup.ValidationError(errors);
      }

      return true
    })
});

export default validationSchema;