import React from 'react'

export default function TitleSelect(props) {
  const options = ['Mr.', 'Mrs.']
  const { concept, onChange, value } = props;
  const handleChange = (ev) => {
    onChange(concept, ev.target.value)
  }

  return (
    <select onChange={handleChange} value={value} className='wd-100-percent hg-40 gray-border'>
      { options.map((option) => <option value={option} key={option}>{option}</option>)}
    </select>
  )
}
