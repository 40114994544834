import Translation from "./Translation";

export interface Type {
  id: number
  name: string
  one_way_price_cents: number
  round_trip_price_cents: number
  translations: Array<Translation>
}

interface Luggage {
  typeId: string | number,
  quantity: string | number
}

export class ConfirmedLuggage {
  constructor(
    public type: Type,
    public luggage: Luggage,
  ) { };

  isBicycle(): boolean {
    return this.luggage.typeId === 1;
  }

  quantity(): number {
    return typeof this.luggage.quantity === 'string' ? parseInt(this.luggage.quantity) : this.luggage.quantity;
  }
}

export default Luggage;